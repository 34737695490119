<template>
  <div class="ModermPO">
    <ContactInfo></ContactInfo>
    <img
      class="animate__animated"
      v-scroll="'animate__fadeIn'"
      src="@assets/images/ModernPO1.png"
      alt=""
      width="100%"
    />
    <div class="number1">
      <img src="@assets/images/SystemBuild2.png" alt="" width="100%" />
      <div class="contain">
        <div class="Rem0_4 animate__animated" v-scroll="'animate__fadeInDown'">
          <p style="font-weight: normal !important">服务介绍</p>
        </div>
        <p
          class="Rem0_24 animate__animated"
          v-scroll="'animate__fadeIn'"
          style="
            animation-delay: 1s;
            font-weight: normal !important;
            text-indent: 2em;
          "
        >
          景翔研究流程管理近二十年，辅导过各行各业各种规模的流程优化，积累了丰富实战经验，并形成一套成熟的流程优化方法论。我们一直致力于为企业搭建围绕战略目标的流程体系，主张建立“以过程为导向”的管理模式，通过对核心业务流程的贯通和优化，解决管理接口、信息孤岛等问题，并将实战体会荟萃在《基于流程和战略的组织设计》、《过程方法》专著上。
        </p>
        <div class="Rem0_4 animate__animated" v-scroll="'animate__fadeInDown'">
          <p style="font-weight: normal !important">服务内容:</p>
        </div>
        <div class="ul">
          <span
            class="Rem0_25 animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list1"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
        <div class="ul">
          <span
            class="Rem0_25 animate__animated"
            v-scroll="'animate__fadeInRightBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list2"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
        <div class="ul">
          <span
            class="Rem0_25 animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list3"
            :key="index"
            :class="{ dispalyNone: !item }"
          >
            {{ item }}</span
          >
        </div>
        <div class="ul">
          <span
            class="Rem0_25 animate__animated"
            v-scroll="'animate__fadeInRightBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list4"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
      </div>
    </div>
    <div class="number3">
      <img src="../../../assets/images/NumToGround4.png" alt="" />
      <div class="contain">
        <div class="Rem0_4">
          <p>流程优化的特点</p>
          <p></p>
        </div>
        <div class="fivebox">
          <div
            class="animate__animated"
            v-scroll="'animate__rollIn'"
            v-for="(item, index) in featureList"
            :key="index"
          >
            <img :src="item.img" />
            <p class="p1 Rem0_24">
              {{ item.p1 }}
            </p>
            <p class="p2 Rem0_20">
              {{ item.p2 }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="number4">
      <div v-scroll="'animate__bounceIn'" class="Rem0_4 animate__animated">
        <p>为什么要做流程优化？</p>
        <p></p>
      </div>
      <div class="box">
        <div
          class="box-item animate__animated"
          v-for="(item, index) in hexagonList"
          :key="index"
          v-scroll="'animate__fadeInDown'"
        >
          <div class="hexagon">{{ index + 1 }}</div>
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>
    <div class="number5">
      <img class="img1" src="../../../assets/images/ModernPO3.png" alt="" />
      <div class="Rem0_4">
        <p>景翔流程管理研究成果</p>
        <p></p>
      </div>
      <img class="img2" src="../../../assets/images/ModernPO4.png" alt="" />
    </div>
    <div class="number6">
      <div v-scroll="'animate__bounceIn'" class="Rem0_4 animate__animated">
        <p>部分成果</p>
        <p></p>
      </div>
      <div class="tabBox" id="slideshow">
        <div class="tab">
          <div
            class="tab-item"
            v-for="(item, index) in tabList"
            :key="index"
            @click="handleTab(index)"
          >
            <div class="tabBtn Rem0_30" :class="{ active: index === tabIndex }">
              {{ item }}
            </div>
            <img
              v-show="index == tabIndex"
              src="../../../assets/images/ProcessOptimize21.png"
              alt
            />
          </div>
        </div>
        <div class="index1" v-show="tabIndex == 0">
          <img src="../../../assets/images/ModernPO6.png" alt="" />
          <p class="Rem0_24">
            职能分解和优化后要全部被流程覆盖，这是直接将“职能”向“流程”转化最直接的方法
          </p>
        </div>
        <div class="index2" v-show="tabIndex == 1">
          <img src="../../../assets/images/ModernPO7.png" alt="" />
          <p class="Rem0_22">
            根据公司未来发展及管理的需要，系统性梳理各管理模块是非常有必要。通过对规范化管理体系树的了解，可以分步骤推动公司管理制度、作业流程、产品标准、检验规范及作业指导书修订和编制工作，逐步形成规范化管理体系。
          </p>
        </div>
        <div class="index3" v-show="tabIndex == 2">
          <img src="../../../assets/images/ModernPO8.png" alt="" />
          <p class="Rem0_26">作业文件网格化</p>
          <div class="p-box">
            <p class="Rem0_22 p1">
              景翔独立开发出流程优化分析系统，协助企业对流程进行分解和分析，让流程缺陷一目了然，还有分类、筛选、大数据等功能，让流程优化更加
            </p>
            <p class="Rem0_22 p2">
              1.职能三级分解和优化后，针对管理流程进行分解，分解与部门、负责人、过程要求同步进行。
            </p>
            <p class="Rem0_22 p2">2.文件网格化后，除了可以对流程进行分</p>
          </div>
        </div>
        <div class="index4" v-show="tabIndex == 3">
          <div>
            <div>
              <p class="Rem0_30">岗位职责大数据分析</p>
              <img
                class="img1"
                src="../../../assets/images/ModernPO11.png"
                alt=""
              />
              <img
                class="img2"
                src="../../../assets/images/ModernPO12.png"
                alt=""
              />
            </div>
            <img
              class="img3"
              src="../../../assets/images/ModernPO9.png"
              alt=""
            />
          </div>
          <img
            class="img4"
            src="../../../assets/images/ModernPO10.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactInfo from "../../../components/public/ContactInfo.vue";
export default {
  components: { ContactInfo },
  data() {
    return {
      serveList: {
        list1: [
          "三级职能分解和优化",
          "构建新型价值链",
          "构建体系树",
          "流程分类",
          "流程活动分类",
        ],
        list2: [
          "输入输出分类",
          "流程六要素格式",
          "流程目标指标",
          "流程活动要求",
          "流程要素数字化",
        ],
        list3: [
          "流程分解",
          "明确流程职责",
          "流程接口",
          "信息流优化",
          "岗位职责优化",
        ],
        list4: [
          "关键信息流",
          "流程优化",
          "构建流程标准组件",
          "流程承包制",
          "流程项目制",
        ],
      },
      featureList: [
        {
          img: require("@assets/images/a.png"),
          p1: "独创的方法",
          p2: "归纳了哈默的流程再造、波特的价值链、高德拉特的TOC方法的优点，独创了一整套过程优化的方法论，能有效帮助企业从“职能型”转向“流程型”",
        },
        {
          img: require("@assets/images/b.png"),
          p1: "专业软件的支持",
          p2: "配有专门的软件，该软件简单实用、易操作，适用于任何类型的组织和不同层次的人员操作，在大幅减少优化工作量的同时还能提高优化效果",
        },
        {
          img: require("@assets/images/c.png"),
          p1: "方便与其它领域接口",
          p2: "流程优化的一个重要结果是为各个环节规定了输入输出，这样就方便与其它软件（如ERP、MAS等）、人力资源管理、绩效考核、财务接口",
        },
        {
          img: require("@assets/images/d.png"),
          p1: "管理扁平化",
          p2: "流程优化后，将是“以横向信息流为主，纵向信息流为次”的体系模式，直接导致了管理扁平化，大幅提升的经营效率 ",
        },
        {
          img: require("@assets/images/d.png"),
          p1: "大幅降本增效",
          p2: "在过程中分配人员、在过程中分配职责和权限，这才是科学的人力资源配置，这种方法至少可较少20%的人员 ",
        },
      ],
      hexagonList: [
        {
          title: "为战略实现提供 体系保证",
        },
        {
          title: "确保各业务板块 流程高效",
        },
        {
          title: "理顺各部门和层级之间接口，避免管理真空",
        },
        {
          title: "减少管理制度使体系“瘦身",
        },
        {
          title: "建立标准化管理模式，可快速复制",
        },
        {
          title: "为业务数字化建立流程基础",
        },
      ],
      tabList: [
        "职能三级分解和优化",
        "以流程为基础的体系树",
        "流程优化分析软件",
        "岗位职责大数据分析",
      ],
      tabIndex: 0,
      tabImg: [
        require("../../../assets/images/ProcessOptimize22.png"),
        require("../../../assets/images/ProcessOptimize23.png"),
        require("../../../assets/images/ProcessOptimize24.png"),
        require("../../../assets/images/ProcessOptimize25.png"),
        require("../../../assets/images/ProcessOptimize26.png"),
      ],
      setIntervalDiv: {},
      slideshow: null,
    };
  },
  mounted() {
    this.setTimeoutImg();
    this.slideshow = document.getElementById("slideshow");
    this.slideshow.addEventListener("mouseover", this.stopSlideshow);
    this.slideshow.addEventListener("mouseout", this.startSlideshow);
  },
  methods: {
    handleTab(index) {
      this.tabIndex = index;
    },
    setTimeoutImg() {
      var _this = this;
      this.setIntervalDiv = setInterval(() => {
        _this.tabIndex = _this.tabIndex + 1;
        console.log(_this.tabIndex);
        if (_this.tabIndex == 4) {
          _this.tabIndex = 0;
        }
      }, 1800);
    },
    stopSlideshow() {
      clearInterval(this.setIntervalDiv);
    },
    startSlideshow() {
      this.setTimeoutImg()
    },
  },
  destroyed() {
    clearTimeout(this.setIntervalDiv);
  },
};
</script>

<style lang="less" scoped>
.ModermPO {
  .number1 {
    position: relative;
    .contain {
      width: 80%;
      margin: 0 10%;
      position: absolute;
      top: 0;
      color: white;
      text-align: start;
      .Rem0_4:nth-of-type(1) {
        display: flex;
        justify-content: center;
        margin-top: 2%;
      }
      .Rem0_4:nth-of-type(2) {
        margin: 1% 0;
      }
      p:nth-of-type(1) {
        padding-top: 30px;
      }
      .ul {
        width: 100%;
        display: flex;
        justify-content: space-between;
        opacity: 0.83;
        span {
          flex: 1;
          border: 1px solid;
          text-align: center;
          border: 1px solid #909090;
          border-radius: 5px;
          margin: 0 6px;
          margin-top: 20px;
          background: linear-gradient(to right, #555974, #5d616f);
          padding: 0.18rem 0;
          cursor: default;
        }
        .dispalyNone {
          background: transparent;
          border: transparent;
        }
      }
    }
  }
  .number3 {
    position: relative;
    > img {
      width: 100%;
    }
    .contain {
      position: absolute;
      width: 80%;
      margin: 0 10%;
      text-align: center;
      top: 0;
      .Rem0_4 {
        margin-top: 5%;
        color: #4375c8;
        p:nth-of-type(2) {
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .fivebox {
      display: flex;
      justify-content: space-between;
      margin-top: 8%;
      div:hover {
        background: #4375c8;
        .p1 {
          color: white !important;
          transform: scale(1.25);
        }
        .p2 {
          color: white !important;
        }
      }
      div {
        flex: 1;
        text-align: center;
        padding: 35px 20px 15px 20px;
        border-radius: 15px;
        margin: 1%;
        background: #f6f6f6;
        img {
          width: 0.65rem;
          height: 0.65rem;
        }
        .p1 {
          font-weight: bold;
          margin-top: 10px;
          margin-bottom: 20px;
          color: #4375c8;
        }
        .p2 {
          line-height: 0.35rem !important;
          text-align: start;
          color: #333333;
        }
      }
    }
  }
  .number4 {
    position: relative;
    height: 8.4rem;
    background: url("../../../assets/images/ModernPO5.png") no-repeat;
    background-size: 100% 100%;
    .Rem0_4 {
      text-align: center;
      padding-top: 3%;
      color: #4375c8;
      p:nth-of-type(2) {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .box {
      .box-item {
        position: absolute;
        font-weight: 400;
        font-size: 0.24rem;
        text-shadow: 0px 1px 1px rgba(140, 172, 209, 0.59);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .hexagon {
          width: 0.545rem;
          height: 0.63rem;
          background-color: #c84343;
          position: relative;
          clip-path: polygon(
            50% 0%,
            100% 25%,
            100% 75%,
            50% 100%,
            0% 75%,
            0% 25%
          );
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
        }
        p {
          margin-top: 0.1rem;
          color: #154696;
          line-height: 0.3rem;
          font-size: 0.24rem;
          font-weight: 400;
        }
      }
      & > div:nth-child(1) {
        width: 1.7rem;
        bottom: 56.25%;
        left: 10.14%;
      }
      & > div:nth-child(2) {
        width: 1.7rem;
        bottom: 45.86%;
        left: 25.24%;
      }
      & > div:nth-child(3) {
        width: 1.8rem;
        bottom: 52.86%;
        left: 38%;
      }
      & > div:nth-child(4) {
        width: 1.46rem;
        bottom: 45%;
        right: 39%;
      }
      & > div:nth-child(5) {
        width: 1.92rem;
        bottom: 57.67%;
        right: 25.09%;
      }
      & > div:nth-child(6) {
        width: 1.44rem;
        bottom: 54%;
        right: 11.58%;
      }
    }
  }
  .number5 {
    position: relative;
    text-align: center;
    width: 100%;
    .img1 {
      width: 100%;
    }
    .Rem0_4 {
      position: absolute;
      top: 18%;
      color: #4375c8;
      transform: translate(-50%, -50%);
      left: 50%;
      p {
        left: 40%;
      }
    }
    .img2 {
      position: absolute;
      width: 66%;
      left: 17%;
      bottom: 4.5%;
    }
  }
  .number6 {
    position: relative;
    min-height: 9.54rem;
    padding-bottom: 3%;

    .Rem0_4 {
      text-align: center;
      padding-top: 3%;
      color: #4375c8;
      p:nth-of-type(2) {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .tabBox {
      width: 80%;
      margin: 0 auto;
      margin-top: 0.6rem;
      text-align: center;
      .tab {
        display: flex;
        justify-content: space-around;
        margin-bottom: 1%;
        .tab-item {
          position: relative;
          .tabBtn {
            min-width: 2.68rem;
            background: #ffffff;
            border-radius: 0.4rem;
            border: 1px solid #e5e6e7;
            height: 0.78rem;
            line-height: 0.78rem;
            font-weight: 400;
            color: #000000;
            text-align: center;
            box-sizing: border-box;
            padding: 0 0.4rem;
            cursor: pointer;
          }
          img {
            position: absolute;
            left: 40%;
            transform: translateX(-50%);
            bottom: -0.15rem;
            width: 2.14rem;
            height: 0.08rem;
          }
          .active {
            font-weight: bold;
            color: #ffffff;
            background: #4375c8;
          }
        }
      }
      .index1 {
        img {
          width: 66%;
        }
        p {
          background: #e9f1f9;
          border-radius: 10px;
          width: 66%;
          text-align: center;
          margin: 0 17%;
        }
      }
      .index2 {
        display: flex;
        width: 85%;
        margin: 0 7.5%;
        padding: 2%;
        img {
          width: 76%;
        }
        p {
          padding-top: 2%;
          padding-left: 0.5%;
        }
      }
      .index3 {
        width: 100%;
        img {
          width: 100%;
        }
        .Rem0_26 {
          color: #4375c8;
          font-weight: bold;
          text-align: center;
          padding-bottom: 2%;
          padding-top: 1%;
        }
        .p-box {
          background: #e9f1f9;
          padding: 2% 2.5%;
          .p1 {
            font-weight: bold;
            text-align: start;
            padding-bottom: 0.8%;
          }
          .p2 {
            text-align: start;
            line-height: 0.36rem !important;
            color: #3e3e3e;
          }
        }
      }
      .index4 {
        > div {
          display: flex;
          justify-content: space-between;
          padding-top: 2%;
          > div {
            position: relative;
            width: 33%;
            text-align: start;
            padding-left: 6.8%;
            height: 3.5rem;
          }
          .Rem0_30 {
            color: #4375c8;
            font-weight: bold;
          }
          .img1 {
            width: 0.9rem;
            margin-top: 12%;
            margin-left: 5%;
          }
          .img2 {
            position: absolute;
            width: 2rem;
            right: 6%;
            top: 8%;
          }
          .img3 {
            position: absolute;
            width: 57%;
            right: 2%;
          }
        }
        .img4 {
          width: 100%;
          margin-top: 1.2%;
        }
      }
    }
  }
}
</style>